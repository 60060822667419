import { combineReducers } from 'redux';
import user from './user';
import dataRu from './data-ru';
import runtime from './runtime';
import intl from './intl';
import modalReducer from './modal';
import { reducer as responsiveReducer } from 'react-responsive-redux';

export default combineReducers({
  user,
  dataRu,
  modalReducer,
  runtime,
  intl,
  responsive: responsiveReducer,
});
