import { SHOW_MODAL, HIDE_MODAL } from '../constants';

export default function modalReducer(state = {modalIsActive: false}, action) {
  switch(action.type){
    case SHOW_MODAL:
      return {
        ...state,
        modalIsActive: true
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalIsActive: false
      };
    default:
      return state;
  }
}
